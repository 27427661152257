import config from 'config';

export function createSession(parameters) {
    const {
        accessToken, accessTokenTtl, refreshToken, refreshTokenTtl, authorizationCode, state, redirectUri, isResetPasswordEntered,
    } = parameters;
    createCookieAccess(accessToken, accessTokenTtl);
    createCookieRefresh(refreshToken, refreshTokenTtl);
    redirectsToAdmin(authorizationCode, state, redirectUri, isResetPasswordEntered);
}

function createCookieAccess(accessToken, accessTokenTtl) {
    const cookie = [];
    cookie.push(`VfGAccess=${accessToken}`);
    cookie.push(`Max-Age=${accessTokenTtl}`);
    cookie.push(`Domain=${config.cookieDomain}`);
    cookie.push('Path=/');
    cookie.push('Secure');
    cookie.push('SameSite=Lax');
    document.cookie = cookie.join('; ');
}

function createCookieRefresh(refreshToken, refreshTokenTtl) {
    const cookie = [];
    cookie.push(`VfGRefresh=${refreshToken}`);
    cookie.push(`Max-Age=${refreshTokenTtl}`);
    cookie.push(`Domain=${config.cookieDomain}`);
    cookie.push('Path=/');
    cookie.push('Secure');
    cookie.push('SameSite=Lax');
    document.cookie = cookie.join('; ');
}

function redirectsToAdmin(authorizationCode, state, redirectUri, isResetPasswordEntered) {
    const baseUrl = redirectUri || config.adminUrl;
    let url = `${baseUrl}?code=${authorizationCode}&state=${state}`;
    if (isResetPasswordEntered) {
        url += '&reset_password_entered=y';
    }
    window.location = url;
}
