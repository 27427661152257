<template>
    <v-dialog
        width="auto"
        :model-value="isVisible"
        @update:model-value="$emit('close')"
    >
        <v-progress-circular
            indeterminate
            :size="100"
            :width="10"
        />
    </v-dialog>
</template>

<script>
export default {
    props: {
        isVisible: {
            required: true,
            type: Boolean,
        },
    },
    emits: [ 'close' ],
};
</script>
