import { getLowerEnvironmentVariables } from './variables/lower-environment.js';
import { getProductionVariables } from './variables/production.js';

function getConfig() {
    try {
        if (isLowerEnvironment()) {
            const env = getEnviromentName();
            return getLowerEnvironmentVariables(env);
        }
        return getProductionVariables();
    }
    catch (error) {
        return getProductionVariables();
    }
}

function isLowerEnvironment() {
    const hostname = window.location.hostname;
    return Boolean(hostname.match(/(vf-(test|dev)[0-9]*)\.org$/g));
}

function getEnviromentName() {
    const hostname = window.location.hostname;
    const env = hostname.match(/(vf-(test|dev)[0-9]*)/g);
    return env[0];
}

const config = getConfig();
export default config;
