import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import MfaSetupView from '../views/MfaSetupView.vue';
import MfaVerifyView from '../views/MfaVerifyView.vue';
import NotFoundView from '../views/NotFoundView.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/mfa/otp/new',
        name: 'mfa-otp-new',
        component: MfaSetupView,
    },
    {
        path: '/mfa/otp/verify',
        name: 'mfa-otp-verify',
        component: MfaVerifyView,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFoundView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
