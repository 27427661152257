export const MFA_INVALID_CODE_MAX_ATTEMPTS = 3;

// Responses of API
export const API_ERROR_MFA_INVALID_CODE = 'Invalid MFA code';
export const API_ERROR_MANDATORY_PARAMETER = /^\[Bad Request\] Validation error for body application\/json;charset=UTF-8/;
export const API_ERROR_CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND';
export const API_ERROR_INVALID_REDIRECT_URI = 'INVALID_REDIRECT_URI';

// Possible values of error field in the "parameter" store
export const PARAMETER_ERROR_PASSWORD_RESET = 'password_reset';
export const PARAMETER_ERROR_BAD_STATE = 'bad_state';
