<template>
    <main class="vf-app__container">
        <router-view />
    </main>
</template>

<style scoped>
.vf-app__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 100dvh;
    background-image: url('assets/background.jpg');
    background-repeat: repeat;
    background-position: center;
}
</style>
