<template>
    <v-dialog
        width="auto"
        :model-value="isVisible"
        @update:model-value="$emit('close')"
    >
        <v-card
            max-width="400"
            :title="$i18n('message_dialog.title')"
        >
            <template #text>
                <div v-if="message">
                    {{ message }}
                </div>
                <component
                    v-else
                    :is="componentMessage"
                />
            </template>
            <v-card-actions class="justify-center">
                <v-btn
                    text="Close"
                    color="primary"
                    variant="flat"
                    @click="$emit('close')"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isVisible: {
            required: true,
            type: Boolean,
        },
        message: String,
        componentMessage: Object,
    },
    emits: [ 'close' ],
};
</script>
