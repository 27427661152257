import { defineStore } from 'pinia';

export const useMfaStore = defineStore('mfa', {
    state: () => ({
        isMfaEnabled: false,
        isMfaConfigured: false,
        mfaToken: null,
        mfaSession: null,
        mfaCodesUsed: [],
        mfaAttemps: 0,
    }),
});
