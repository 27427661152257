<template>
    <div class="vf-view__container elevation-5 bg-white">
        <slot />
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.vf-view__container {
    padding: 1rem;
    margin: 1rem;
}
</style>
