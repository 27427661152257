<template>
    <ViewContainer class="vf-mfa-setup__container">
        <h1 class="vf-mfa-setup__title">
            {{ $i18n('mfa-setup.title') }}
        </h1>
        <p class="vf-mfa-setup__caption">
            {{ $i18n('mfa-setup.caption') }}
        </p>
        <v-form
            validate-on="input"
            @submit.prevent="submit"
            v-model="isFormValidated"
        >
            <div class="vf-mfa-setup__qrcode-wrapper">
                <canvas ref="qrcode" />
            </div>
            <v-textarea
                :label="$i18n('mfa.fields.token')"
                variant="outlined"
                :auto-grow="true"
                :rows="1"
                :readonly="true"
                v-model="mfaToken"
            >
                <template #append-inner>
                    <v-btn
                        variant="plain"
                        @click="copyToClipboard"
                    >
                        {{ $i18n('mfa-setup.actions.copy') }}
                    </v-btn>
                </template>
            </v-textarea>
            <v-text-field
                :label="$i18n('mfa.fields.code')"
                variant="outlined"
                type="number"
                :rules="[ validateMandatoryField, validateMfaCode ]"
                v-model="mfaCode"
            />
            <div class="vf-mfa-setup__actions">
                <v-btn
                    type="submit"
                    color="primary"
                    variant="flat"
                    :disabled="!isFormValidated"
                >
                    {{ $i18n('mfa-setup.actions.configure') }}
                </v-btn>
            </div>
        </v-form>
        <DialogMessage
            :is-visible="isDialogMessageVisible"
            :message="dialogMessage"
            :component-message="dialogComponentMessage"
            @close="closeDialogMessage"
        />
        <DialogLoading
            :is-visible="isLoadingVisible"
        />
    </ViewContainer>
</template>

<script>
import { shallowRef } from 'vue';
import { mapWritableState } from 'pinia';
import debugLog from '@vf/debug-log';
import config from 'config';
import ViewContainer from '@/components/ViewContainer.vue';
import DialogMessage from '@/components/DialogMessage.vue';
import DialogLoading from '@/components/DialogLoading.vue';
import ErrorGenericMessage from '@/components/ErrorGenericMessage.vue';
import { useUserStore } from '@/store/user.js';
import { useMfaStore } from '@/store/mfa.js';
import { validateMandatoryField, validateMfaCode } from '@/validations/main.js';
import { configureMfa } from '@/http/endpoint/auth-service.js';
import { MFA_INVALID_CODE_MAX_ATTEMPTS, API_ERROR_MFA_INVALID_CODE } from '@/constants/main.js';
import {
    API_ERROR_MANDATORY_PARAMETER,
    API_ERROR_CLIENT_NOT_FOUND,
    API_ERROR_INVALID_REDIRECT_URI,
} from '@/constants/main.js';

export default {
    components: {
        ViewContainer,
        DialogLoading,
        DialogMessage,
    },
    data: () => ({
        mfaCode: null,
        isFormValidated: false,
        isDialogMessageVisible: false,
        dialogMessage: null,
        dialogComponentMessage: null,
        dialogCallbackClosed: null,
        isLoadingVisible: false,
        isMfaAttemptsExceeded: false,
    }),
    computed: {
        ...mapWritableState(useUserStore, [ 'email' ]),
        ...mapWritableState(useMfaStore, [ 'isMfaEnabled', 'isMfaConfigured', 'mfaToken', 'mfaSession', 'mfaCodesUsed', 'mfaAttemps' ]),
    },
    mounted() {
        import(/* webpackChunkName: "qrcode" */'qrcode').then(this.renderQrCode);

        this.mfaAttemps = 0;
        if (!this.isMfaEnabled || this.isMfaConfigured || !this.mfaToken || !this.mfaSession) {
            this.redirectToLoginView();
        }
    },
    methods: {
        validateMandatoryField,
        validateMfaCode,
        configureBodyRequest() {
            return {
                mfa_session: this.mfaSession,
                mfa_code: this.mfaCode,
            };
        },
        async submit() {
            if (this.isFormValidated) {
                try {
                    this.isLoadingVisible = true;
                    this.mfaAttemps++;
                    this.resetDialogMessage();

                    const res = await configureMfa(this.configureBodyRequest());
                    if (res.data.mfa_status === 'SUCCESS') {
                        this.mfaSession = null; // This MFA Session was only valid for one request, now we need to clean
                        this.mfaCodesUsed.push(this.mfaCode);
                        this.isMfaConfigured = true;

                        this.showDialogMessage(this.$i18n('mfa-setup.successfully_configured'), null, this.redirectToMfaVerifyView);
                    }
                    else {
                        throw { errorMessage: this.$i18n('errors.invalid_mfa_code') };
                    }
                }
                catch (exception) {
                    // For all errors, redirect the user to the login page
                    // Except when it receives "Invalid MFA Code", then wait three times
                    if (exception instanceof Response) {
                        await this.handleApiException(exception);
                    }
                    else {
                        this.handleInternalExpcetion(exception);
                    }
                }
                finally {
                    this.isLoadingVisible = false;
                }
            }
        },
        async handleApiException(exception) {
            const res = await exception.json();

            if (res.error === API_ERROR_MFA_INVALID_CODE) {
                if (this.mfaAttemps >= MFA_INVALID_CODE_MAX_ATTEMPTS) {
                    this.isMfaAttemptsExceeded = true;
                    this.showDialogMessage(this.$i18n('errors.mfa_too_many_attemps'), null, this.onCloseErrorDialog);
                    return;
                }
                this.showDialogMessage(res.error, null, this.onCloseErrorDialog);
                return;
            }

            this.isMfaAttemptsExceeded = true;

            if (
                API_ERROR_MANDATORY_PARAMETER.test(res.error)
                || API_ERROR_INVALID_REDIRECT_URI === res.error
                || API_ERROR_CLIENT_NOT_FOUND === res.error
            ) {
                this.showDialogMessage(null, shallowRef(ErrorGenericMessage), this.onCloseErrorDialog);
                return;
            }

            this.showDialogMessage(res.error, null, this.onCloseErrorDialog);
        },
        handleInternalExpcetion(exception) {
            this.isMfaAttemptsExceeded = true;
            if (exception.errorMessage) {
                this.showDialogMessage(exception.errorMessage, null, this.onCloseErrorDialog);
            }
            else {
                this.showDialogMessage(this.$i18n('errors.unexpected'), null, this.onCloseErrorDialog);
            }
        },
        redirectToMfaVerifyView() {
            this.$router.push({
                name: 'mfa-otp-verify',
                query: this.$route.query,
            });
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.mfaToken);
        },
        redirectToLoginView() {
            this.$router.push({
                name: 'login',
                query: this.$route.query,
            });
        },
        showDialogMessage(message, component, callbackClosed) {
            this.isDialogMessageVisible = true;
            this.dialogMessage = message;
            this.dialogComponentMessage = component;
            this.dialogCallbackClosed = callbackClosed;
        },
        closeDialogMessage() {
            this.isDialogMessageVisible = false;
            if (this.dialogCallbackClosed && typeof this.dialogCallbackClosed === 'function') {
                this.dialogCallbackClosed();
            }
        },
        resetDialogMessage() {
            this.isDialogMessageVisible = false;
            this.dialogMessage = null;
            this.dialogComponentMessage = null;
            this.dialogCallbackClosed = null;
        },
        onCloseErrorDialog() {
            if (this.isMfaAttemptsExceeded) {
                this.redirectToLoginView();
            }
        },
        renderQrCode(qrcode) {
            // This QRCode text follows these rules:
            //   https://datatracker.ietf.org/doc/html/draft-linuxgemini-otpauth-uri-00
            //   https://github.com/google/google-authenticator/wiki/Key-Uri-Format

            const text = `otpauth://totp/${this.email}?secret=${this.mfaToken}&issuer=${config.mfaIssuer}`;
            const cb = (error) => error && debugLog('Problems to render QRCode:', error);
            const options = {
                width: 300,
                margin: 2,
            };

            qrcode.toCanvas(this.$refs.qrcode, text, options, cb);
        },
    },
};
</script>

<style lang="scss" scoped>
.vf-mfa-setup {
    &__container {
        min-width: 300px;
        flex-basis: 600px;
    }
    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }
    &__caption {
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
    }
    &__actions {
        margin-top: 1rem;
        text-align: center;
    }
    &__qrcode-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
</style>
