<template>
    <ViewContainer class="vf-mfa-verify__container">
        <h1 class="vf-mfa-verify__title">
            {{ $i18n('mfa-verify.title') }}
        </h1>
        <p class="vf-mfa-verify__caption">
            {{ $i18n('mfa-verify.caption') }}
        </p>
        <v-form
            validate-on="input"
            @submit.prevent="submit"
            v-model="isFormValidated"
        >
            <v-text-field
                :label="$i18n('mfa.fields.code')"
                variant="outlined"
                type="number"
                :rules="[ validateMandatoryField, validateMfaCode, validateNewMfaCode ]"
                v-model="mfaCode"
            />
            <div class="vf-mfa-verify__actions">
                <v-btn
                    type="submit"
                    color="primary"
                    variant="flat"
                    :disabled="!isFormValidated"
                >
                    {{ $i18n('mfa-verify.actions.verify') }}
                </v-btn>
            </div>
        </v-form>
        <DialogMessage
            :is-visible="isDialogMessageVisible"
            :message="dialogMessage"
            :component-message="dialogComponentMessage"
            @close="closeDialogMessage"
        />
        <DialogLoading
            :is-visible="isLoadingVisible"
        />
    </ViewContainer>
</template>

<script>
import { shallowRef } from 'vue';
import { mapWritableState } from 'pinia';
import ViewContainer from '@/components/ViewContainer.vue';
import ErrorGenericMessage from '@/components/ErrorGenericMessage.vue';
import { useUserStore } from '@/store/user.js';
import { useMfaStore } from '@/store/mfa.js';
import { useParametersStore } from '@/store/parameters.js';
import { validateMandatoryField, validateNewMfaCode, validateMfaCode } from '@/validations/main.js';
import { authorizeUser } from '@/http/endpoint/auth-service.js';
import { createSession } from '@/session/main.js';
import DialogMessage from '@/components/DialogMessage.vue';
import DialogLoading from '@/components/DialogLoading.vue';
import { MFA_INVALID_CODE_MAX_ATTEMPTS, API_ERROR_MFA_INVALID_CODE } from '@/constants/main.js';
import {
    API_ERROR_MANDATORY_PARAMETER,
    API_ERROR_CLIENT_NOT_FOUND,
    API_ERROR_INVALID_REDIRECT_URI,
} from '@/constants/main.js';

export default {
    components: {
        ViewContainer,
        DialogMessage,
        DialogLoading,
    },
    data: () => ({
        mfaCode: null,
        isFormValidated: false,
        isDialogMessageVisible: false,
        dialogMessage: null,
        dialogComponentMessage: null,
        isLoadingVisible: false,
        isMfaAttemptsExceeded: false,
    }),
    computed: {
        ...mapWritableState(useUserStore, [ 'domain', 'email', 'password' ]),
        ...mapWritableState(useMfaStore, [ 'isMfaEnabled', 'isMfaConfigured', 'mfaToken', 'mfaSession', 'mfaCodesUsed', 'mfaAttemps' ]),
        ...mapWritableState(useParametersStore, [ 'scope', 'clientId', 'redirectUri', 'state' ]),
    },
    mounted() {
        this.mfaAttemps = 0;
        if (!this.isMfaEnabled || !this.isMfaConfigured) {
            this.redirectToLoginView();
        }
    },
    methods: {
        validateMandatoryField,
        validateNewMfaCode,
        validateMfaCode,
        baseBodyRequest() {
            return {
                domain: this.domain,
                email: this.email,
                password: this.password,
                scope: this.scope,
                client_id: this.clientId,
                redirect_uri: this.redirectUri,
                state: this.state,
            };
        },
        mfaBodyRequest() {
            return {
                ...this.baseBodyRequest(),
                mfa_session: this.mfaSession,
                mfa_code: this.mfaCode,
            };
        },
        async submit() {
            if (this.isFormValidated) {
                try {
                    this.isLoadingVisible = true;
                    this.resetDialogMessage();

                    // If we don't have MFA session, first we need to call "authorize" endpoint to get the mfa session,
                    // Then we can call "authorize" again to validate MFA code
                    if (!this.mfaSession) {
                        await this.initializeMfaSession();
                    }
                    await this.sendMfaCode();
                }
                catch (exception) {
                    // For all errors, redirect the user to the login page
                    // Except when it receives "Invalid MFA Code", then wait three times
                    if (exception instanceof Response) {
                        await this.handleApiException(exception);
                    }
                    else {
                        this.handleInternalExpcetion(exception);
                    }
                }
                finally {
                    this.isLoadingVisible = false;
                }
            }
        },
        async handleApiException(exception) {
            this.isDialogMessageVisible = true;
            const res = await exception.json();

            if (res.error === API_ERROR_MFA_INVALID_CODE) {
                if (this.mfaAttemps >= MFA_INVALID_CODE_MAX_ATTEMPTS) {
                    this.isMfaAttemptsExceeded = true;
                    this.dialogMessage = this.$i18n('errors.mfa_too_many_attemps');
                    return;
                }
                this.dialogMessage = res.error;
                return;
            }

            this.isMfaAttemptsExceeded = true;

            if (
                API_ERROR_MANDATORY_PARAMETER.test(res.error)
                || API_ERROR_INVALID_REDIRECT_URI === res.error
                || API_ERROR_CLIENT_NOT_FOUND === res.error
            ) {
                this.dialogComponentMessage = shallowRef(ErrorGenericMessage);
                return;
            }

            this.dialogMessage = res.error;
        },
        handleInternalExpcetion(exception) {
            this.isMfaAttemptsExceeded = true;
            if (exception.errorMessage) {
                this.dialogMessage = exception.errorMessage;
            }
            else {
                this.dialogMessage = this.$i18n('errors.unexpected');
            }
            this.isDialogMessageVisible = true;
        },
        startSession(res) {
            createSession({
                accessToken: res.data.access_token,
                accessTokenTtl: res.data.access_token_ttl,
                refreshToken: res.data.refresh_token,
                refreshTokenTtl: res.data.refresh_token_ttl,
                authorizationCode: res.data.code,
                state: this.state,
                redirectUri: this.redirectUri,
                isResetPasswordEntered: res.data.reset_password_entered,
            });
        },
        async initializeMfaSession() {
            const res = await authorizeUser(this.baseBodyRequest());
            if (res.data.mfa_session) {
                this.mfaSession = res.data.mfa_session;
            }
            else {
                throw { errorMessage: this.$i18n('errors.unexpected') };
            }
        },
        async sendMfaCode() {
            this.mfaAttemps++;
            const res = await authorizeUser(this.mfaBodyRequest());
            if (res.data.access_token) {
                this.startSession(res);
            }
            else {
                throw { errorMessage: this.$i18n('errors.unexpected') };
            }
        },
        redirectToLoginView() {
            this.$router.push({
                name: 'login',
                query: this.$route.query,
            });
        },
        closeDialogMessage() {
            this.isDialogMessageVisible = false;
            if (this.isMfaAttemptsExceeded) {
                this.redirectToLoginView();
            }
        },
        resetDialogMessage() {
            this.isDialogMessageVisible = false;
            this.dialogMessage = null;
            this.dialogComponentMessage = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.vf-mfa-verify {
    &__container {
        min-width: 300px;
        flex-basis: 600px;
    }
    &__title {
        text-align: center;
        margin-bottom: 0.5rem;
    }
    &__caption {
        margin-bottom: 1rem;
        text-align: center;
    }
    &__actions {
        margin-top: 1rem;
        text-align: center;
    }
}
</style>
