import { i18n } from '@/languages/main.js';
import { useMfaStore } from '@/store/mfa.js';

export function validateMandatoryField(value) {
    return value ? true : i18n('validation_messages.required_field');
}

export function validateEmail(value) {
    return /.+@.+\..+/.test(value) ? true : i18n('validation_messages.invalid_email');
}

export function validateMfaCode(code) {
    const LENGTH_MFA_CODE = 6;
    return code.toString().length !== LENGTH_MFA_CODE ? i18n('validation_messages.invalid_mfa_code') : true;
}

export function validateNewMfaCode(code) {
    const codeUsed = useMfaStore()?.mfaCodesUsed?.find((codeUsed) => codeUsed === code);
    return codeUsed ? i18n('validation_messages.mfa_code_used') : true;
}
