import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        domain: null,
        email: null,
        password: null,
    }),
    actions: {
        reset() {
            this.domain = null;
            this.email = null;
            this.password = null;
        },
    },
});
