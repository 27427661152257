import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createVuetify } from 'vuetify';

import App from './App.vue';
import router from './router/main.js';
import { initializeLanguageModule, vuePluginI18n } from './languages/main.js';

import './styles/main.scss';
import 'vuetify/styles';

initializeLanguageModule();

const pinia = createPinia();
const vuetify = createVuetify({
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#B00020',
                },
            },
        },
    },
});

const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(vuePluginI18n());
app.use(pinia);
app.mount('#app');
