export function getFetchArguments(endpoint, params, body) {
    return {
        endpoint,
        params,
        body: JSON.stringify(body),
        config: getFetchConfig(),
        responseInterceptor,
        errorInterceptor,
    };
}

function getFetchConfig() {
    return {
        credentials: 'include',
        mode: 'cors',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json;charset=UTF-8',
        },
    };
}

function responseInterceptor(request, response) {
    if (response.ok) {
        if (!Number(response.headers.get('Content-Length'))) {
            return Promise.resolve(response);
        }

        return response.json()
            .then((data) => {
                response.data = data;
                return response;
            });
    }
    return Promise.reject(response);
}


function errorInterceptor(request, response) {
    return Promise.reject(response);
}
