<template>
    <ViewContainer class="vf-login__container">
        <h1 class="vf-login__title">
            {{ $i18n('login.title') }}
        </h1>
        <v-form
            validate-on="input"
            @submit.prevent="authenticateUser"
            v-model="isFormValidated"
        >
            <v-text-field
                class="mb-2"
                variant="outlined"
                :label="$i18n('login.fields.domain')"
                :rules="[ validateMandatoryField ]"
                v-model="domain"
            />
            <v-text-field
                class="mb-2"
                variant="outlined"
                type="email"
                :label="$i18n('login.fields.email')"
                :rules="[ validateMandatoryField, validateEmail ]"
                v-model="email"
            />
            <v-text-field
                class="mb-2"
                variant="outlined"
                type="password"
                :label="$i18n('login.fields.password')"
                :rules="[ validateMandatoryField ]"
                v-model="password"
            />
            <div class="vf-login__submit">
                <v-btn
                    type="submit"
                    color="primary"
                    variant="flat"
                    :disabled="!isFormValidated"
                >
                    {{ $i18n('login.actions.sign_in') }}
                </v-btn>
            </div>
            <div class="vf-login__forgot-password">
                <a :href="forgotPasswordLink">Forgot password?</a>
            </div>
        </v-form>
        <div class="vf-login__foot text-grey">
            <span>{{ $i18n('footer.powered_by') }}</span>
            <img
                class="vf-login__viafoura-logo"
                :src="ViafouraLogo"
                :alt="$i18n('footer.viafoura_logo')"
            >
        </div>
        <DialogMessage
            :is-visible="isDialogMessageVisible"
            :message="dialogMessage"
            :component-message="dialogComponentMessage"
            @close="isDialogMessageVisible = false"
        />
        <DialogLoading
            :is-visible="isLoadingVisible"
        />
    </ViewContainer>
</template>

<script>
import { shallowRef } from 'vue';
import { mapWritableState, mapActions } from 'pinia';
import config from 'config';
import ViewContainer from '@/components/ViewContainer.vue';
import DialogMessage from '@/components/DialogMessage.vue';
import DialogLoading from '@/components/DialogLoading.vue';
import ErrorGenericMessage from '@/components/ErrorGenericMessage.vue';
import { authorizeUser } from '@/http/endpoint/auth-service.js';
import { forceLogout } from '@/http/endpoint/heimdall.js';
import { createSession } from '@/session/main.js';
import ViafouraLogo from '@/assets/viafoura-logo.svg';
import { useUserStore } from '@/store/user.js';
import { useMfaStore } from '@/store/mfa.js';
import { useParametersStore } from '@/store/parameters.js';
import { validateMandatoryField, validateEmail } from '@/validations/main.js';
import {
    PARAMETER_ERROR_PASSWORD_RESET,
    PARAMETER_ERROR_BAD_STATE,
    API_ERROR_MANDATORY_PARAMETER,
    API_ERROR_CLIENT_NOT_FOUND,
    API_ERROR_INVALID_REDIRECT_URI,
} from '@/constants/main.js';
import debugLog from '@vf/debug-log';

export default {
    components: {
        ViewContainer,
        DialogMessage,
        DialogLoading,
    },
    data: () => ({
        ViafouraLogo,
        isFormValidated: false,
        isDialogMessageVisible: false,
        dialogMessage: null,
        dialogComponentMessage: null,
        isLoadingVisible: false,
        forgotPasswordLink: config.forgotPasswordForm,
    }),
    computed: {
        ...mapWritableState(useUserStore, [ 'domain', 'email', 'password' ]),
        ...mapWritableState(useMfaStore, [ 'isMfaEnabled', 'isMfaConfigured', 'mfaToken', 'mfaSession' ]),
        ...mapWritableState(useParametersStore, [ 'scope', 'clientId', 'redirectUri', 'state', 'error' ]),
    },
    mounted() {
        this.resetUser();
        this.scope = this.$route.query.scope;
        this.clientId = this.$route.query.client_id;
        this.redirectUri = this.$route.query.redirect_uri;
        this.state = this.$route.query.state;
        this.error = this.$route.query.error;

        if (this.error === PARAMETER_ERROR_PASSWORD_RESET) {
            this.dialogMessage = this.$i18n('errors.temporary_password');
            this.isDialogMessageVisible = true;
        }
        else if (this.error === PARAMETER_ERROR_BAD_STATE) {
            this.dialogComponentMessage = shallowRef(ErrorGenericMessage);
            this.isDialogMessageVisible = true;
        }
    },
    methods: {
        ...mapActions(useUserStore, { resetUser: 'reset' }),
        validateMandatoryField,
        validateEmail,
        authorizeBodyRequest() {
            return {
                domain: this.domain,
                email: this.email,
                password: this.password,
                scope: this.scope,
                client_id: this.clientId,
                redirect_uri: this.redirectUri,
                state: this.state,
            };
        },
        async authenticateUser() {
            if (this.isFormValidated) {
                try {
                    this.isLoadingVisible = true;
                    this.resetDialogMessage();

                    await this.clearCookies();

                    const res = await authorizeUser(this.authorizeBodyRequest());
                    if (res.data.access_token) {
                        this.startSession(res);
                    }
                    else if (res.data.mfa_token) {
                        this.redirectToMfaNew(res);
                    }
                    else if (res.data.mfa_session) {
                        this.redirectToMfaVerify(res);
                    }
                    else {
                        throw this.$i18n('errors.unexpected');
                    }
                }
                catch (exception) {
                    this.isDialogMessageVisible = true;
                    if (exception instanceof Response) {
                        this.handleApiException(exception);
                    }
                    else {
                        this.dialogMessage = this.$i18n('errors.unexpected');
                    }
                }
                finally {
                    this.isLoadingVisible = false;
                }
            }
        },
        async handleApiException(exception) {
            this.isDialogMessageVisible = true;

            const res = await exception.json();
            if (
                API_ERROR_MANDATORY_PARAMETER.test(res.error)
                || API_ERROR_INVALID_REDIRECT_URI === res.error
                || API_ERROR_CLIENT_NOT_FOUND === res.error
            ) {
                this.dialogComponentMessage = shallowRef(ErrorGenericMessage);
                return;
            }

            this.dialogMessage = res.error;
        },
        startSession(res) {
            createSession({
                accessToken: res.data.access_token,
                accessTokenTtl: res.data.access_token_ttl,
                refreshToken: res.data.refresh_token,
                refreshTokenTtl: res.data.refresh_token_ttl,
                authorizationCode: res.data.code,
                state: this.state,
                redirectUri: this.redirectUri,
                isResetPasswordEntered: res.data.reset_password_entered,
            });
        },
        redirectToMfaNew(res) {
            this.isMfaEnabled = true;
            this.isMfaConfigured = false;
            this.mfaToken = res.data.mfa_token;
            this.mfaSession = res.data.mfa_session;
            this.$router.push({
                name: 'mfa-otp-new',
                query: this.$route.query,
            });
        },
        redirectToMfaVerify(res) {
            this.isMfaEnabled = true;
            this.isMfaConfigured = true;
            this.mfaSession = res.data.mfa_session;
            this.$router.push({
                name: 'mfa-otp-verify',
                query: this.$route.query,
            });
        },
        resetDialogMessage() {
            this.isDialogMessageVisible = false;
            this.dialogMessage = null;
            this.dialogComponentMessage = null;
        },
        async clearCookies() {
            try {
                await forceLogout();
            }
            catch (error) {
                debugLog('Problems on force logout', error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.vf-login {
    &__container {
        min-width: 300px;
        flex-basis: 600px;
    }
    &__title {
        text-align: center;
        margin-bottom: 2rem;
    }
    &__submit {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }
    &__forgot-password {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 50px;

        a {
            color: black;
        }
    }
    &__foot {
        margin-top: 1.5rem;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__viafoura-logo {
        margin-left: 5px;
        height: 16px;
    }
}
</style>
