<template>
    <ViewContainer>
        <h1>Not found page</h1>
        <p>Invalid URL: {{ $route.path }}</p>
    </ViewContainer>
</template>

<script>
import ViewContainer from '@/components/ViewContainer.vue';

export default {
    components: {
        ViewContainer,
    },
};
</script>
