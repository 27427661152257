<template>
    <div>
        {{ $i18n('errors.generic.part1') }}
        <a :href="`https://${adminEntryUrl}`">{{ adminEntryUrl }}</a>
        {{ $i18n('errors.generic.part2') }}
    </div>
</template>

<script>
import config from 'config';

export default {
    data: () => ({
        adminEntryUrl: config.adminEntryUrl,
    }),
};
</script>

<style scoped>
a {
    color: black;
}
</style>
