export function sendRequest({
    endpoint,
    params,
    body,
    config,
    responseInterceptor,
    errorInterceptor,
}) {
    const url = getUrl(endpoint, params);
    const settings = {
        method: endpoint.method,
        body,
        ...config,
    };
    const request = {
        url,
        settings,
        calledFromErrorInterceptor: false,
    };

    return callFetch(request, responseInterceptor, errorInterceptor);
}

export function callFetch(request, responseInterceptor, errorInterceptor) {
    const responseFn = responseInterceptor.bind(null, request);
    const errorFn = errorInterceptor.bind(null, request);

    return fetch(request.url, request.settings)
        .then(responseFn)
        .catch(errorFn);
}

export function getUrl(endpoint, params) {
    const paramsClone = { ...params };
    let url = endpoint.domain + endpoint.path;
    url = processRouteParams(url, paramsClone);
    url = processQueryParams(url, paramsClone, endpoint.queryParams);

    return url;
}

export function processRouteParams(url, params) {
    const ROUTE_PARAM_PATTERN = /\{\w{0,}\}/g;

    const routeParams = url.match(ROUTE_PARAM_PATTERN);
    if (routeParams) {
        for (const param of routeParams) {
            const paramName = param.slice(1, -1);
            const paramValue = params[paramName];
            url = url.replace(param, paramValue);

            delete params[paramName];
        }
    }
    return url;
}

export function processQueryParams(url, params, allowedParams) {
    if (allowedParams) {
        const query = new URLSearchParams();
        for (const paramName in params) {
            const paramValue = params[paramName];
            const isAllowed = allowedParams.includes(paramName);
            if (isAllowed) {
                query.set(paramName, paramValue);
            }
        }
        const queryString = query.toString();
        if (queryString) {
            return `${url}?${queryString}`;
        }
    }
    return url;
}

export function getEndpoint(domain, path, method, queryParams) {
    return {
        domain,
        path,
        method,
        queryParams,
    };
}
