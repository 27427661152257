import config from 'config';
import { sendRequest, getEndpoint } from '@/http/clients/fetch.js';
import { getFetchArguments } from '@/http/clients/heimdall-config.js';

function getHeimdallEndpoint(path, method, queryParams) {
    return getEndpoint(config.heimdallUrl, path, method, queryParams);
}

export function forceLogout() {
    const endpoint = getHeimdallEndpoint('/user_token/cookie_refresh/force', 'DELETE');
    const fetchArgs = getFetchArguments(endpoint, null);
    return sendRequest(fetchArgs);
}
