import config from 'config';
import { sendRequest, getEndpoint } from '@/http/clients/fetch.js';
import { getFetchArguments } from '@/http/clients/auth-service-config.js';

function getAuthEndpoint(path, method, queryParams) {
    return getEndpoint(config.authServiceUrl, path, method, queryParams);
}

export function authorizeUser(body) {
    const endpoint = getAuthEndpoint('/authorize', 'POST');
    const fetchArgs = getFetchArguments(endpoint, null, body);
    return sendRequest(fetchArgs);
}

export function configureMfa(body) {
    const endpoint = getAuthEndpoint('/users/mfa', 'POST');
    const fetchArgs = getFetchArguments(endpoint, null, body);
    return sendRequest(fetchArgs);
}
