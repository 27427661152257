import IntlMessageFormat from 'intl-messageformat';
import { Language } from '@vf/commons-js/src/i18n/language.js';
import translationsEnUs from './en-us.json';

const DEFAULT_LANGUAGE = 'en';
let languageInstance;

export function initializeLanguageModule() {
    languageInstance = new Language(IntlMessageFormat, DEFAULT_LANGUAGE, translationsEnUs);
}

export function i18n(translationKey, params) {
    return languageInstance.translate(translationKey, params);
}

export function vuePluginI18n() {
    return {
        install(app) {
            app.config.globalProperties.$i18n = i18n;
        },
    };
}
