import { defineStore } from 'pinia';

// Basically, we received these parameters from viafoura-core (aka PHP) and send it to backend.
export const useParametersStore = defineStore('parameters', {
    state: () => ({
        scope: null,
        clientId: null,
        redirectUri: null,
        state: null,

        // This parameter means that we need show an message to the user
        // Check the values of this values in "src/constants/main.js"
        error: null,
    }),
});
